import { useEffect, useState } from "react";
import AOS from "aos";
import { TypeAnimation } from "react-type-animation";
import "aos/dist/aos.css";
import logoApp from "./assets/images/logo.png";
import imgPepeThink from "./assets/images/imgPepeThink.webp";
import imgTwitter from "./assets/images/imgTwitter.webp";
import imgTelegram from "./assets/images/imgTelegram.webp";
import imgLand from "./assets/images/imgLand.svg";
import imgPepeHatThink from "./assets/images/imgPepeHatThink.webp";
import imgH2B1 from "./assets/images/imgH2B1.png";
import imgH2B2 from "./assets/images/imgH2B2.png";
import imgH2B3 from "./assets/images/imgH2B3.png";
import imgH2B4 from "./assets/images/imgH2B4.png";
import imgPepeMeme from "./assets/images/imgPepeMeme.png";
import imgPepePee from "./assets/images/imgPepePee.webp";
import iconMenu from "./assets/images/iconMenu.png";
import iconClose from "./assets/images/iconClose.png";
import iconUp from "./assets/images/iconUp.png";

function App() {
  const [isActive, setIsActive] = useState(false);
  const [isShow, setIsShow] = useState(false);
  useEffect(() => {
    AOS.init({
      duration: 800,
      disable: "mobile",
    });
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 90) {
      setIsShow(true);
    } else {
      setIsShow(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="App overflow-hidden">
      <header
        className="page-header drop-shadow-md py-[44px] px-6
       bg-[#4F9842] font-[PepeFont]"
      >
        <div className="container mx-auto flex items-center justify-between">
          <img
            data-aos="fade-up"
            className="w-[150px] h-[48px] lg:w-[211px] lg:h-[68px] object-cover"
            src={logoApp}
            alt="logo"
          />
          <nav data-aos="fade-up" className="hidden lg:block">
            <div className="flex text-[12px]">
              <div className="p-[10px] m-1 text-white">
                <a href="/">home</a>
              </div>
              <div className="p-[10px] m-1 text-white">
                <a href="#about">about</a>
              </div>
              <div className="p-[10px] m-1 text-white">
                <a href="#howtobuy">how to buy</a>
              </div>
              {/* <div className="p-[10px] m-1 text-white">
                <a href="/">tokenomics</a>
              </div> */}
              <div className="p-[10px] m-1 text-white">
                <a href="#roadmap">roadmap</a>
              </div>
            </div>
          </nav>
          <button
            data-aos="flip-left"
            className="text-white text-[10px] rounded-[60px] border border-white w-[147px] h-[45px] hidden lg:block"
          >
            buy now
          </button>
          <button onClick={() => setIsActive(true)} className="block lg:hidden">
            <img src={iconMenu} alt="menu" />
          </button>
        </div>
      </header>
      <main className="bg-[#4C9541]">
        <div className="relative">
          <section className="section-spepe rotate bg-pepe-buff bg-contain min-h-screen bg-no-repeat opacity-20 bg-right"></section>
          <div
            className="absolute left-0 right-0 top-0 container mx-auto px-6 lg:px-[128px] py-[60px]
           flex flex-col lg:flex-row items-center"
          >
            <div className="content-left text-center">
              <div data-aos="fade-right" className="lg:text-left">
                <h2 className="font-[PepeBoldFont] text-[60px] text-white text-right">
                  $pepe
                </h2>
                <p className="text-[18px] lg:text-[28px] font-[PepeRegularFont] text-white leading-10 text-right mb-12">
                  the most memeable memecoin in existence. The dogs have had
                  their day, it’s time for Pepe to take reign.
                </p>
              </div>
              <div className="flex gap-10 justify-center lg:justify-start">
                <a
                  data-aos="zoom-in"
                  href="https://t.me/reversepepetoken"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="w-20 mr-5" src={imgTwitter} alt="" />
                </a>
                <a
                  data-aos="zoom-in"
                  href="https://twitter.com/reversepepex"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="w-20" src={imgTelegram} alt="" />
                </a>
              </div>
            </div>
            <div data-aos="fade-left" className="content-right w-full h-full">
              <img
                className="w-[280px] mx-auto lg:w-[476px] overflow-hidden object-cover bg-cover rotate"
                src={imgPepeThink}
                alt=""
              />
            </div>
          </div>
        </div>
        <section className="section-about">
          <img
            src={imgLand}
            className="rotate-180 h-[18px] w-full object-cover "
            alt=""
          />
          <div className="container mx-auto px-6 lg:px-[120px] lg:gap-[60px] py-[80px] flex flex-col-reverse lg:flex-row lg:items-center">
            <div data-aos="fade-right" className="content-left flex-shrink-0">
              <img
                className="w-[280px] lg:w-[400px] mx-auto rotate"
                src={imgPepeHatThink}
                alt=""
              />
            </div>
            <div className="content-right break-words">
              <h2 className="text-[27px] lg:text-[56px] pb-[40px] font-[PepeFont] text-white text-left lg:text-center drop-shadow-[0_2px_2px_rgba(0,0,0,1)]">
                about
              </h2>
              <p
                data-aos="fade-left"
                data-aos-duration="400"
                className="text-[18px] break-words lg:text-[23px] text-white font-[PepeRegularFont]"
              >
                Pepe is tired of watching everyone play hot potato with the
                endless derivative ShibaCumGMElonKishuTurboAssFlokiMoon Inu
                coins. The Inu’s have had their day. It’s time for the most
                recognizable meme in the world to take his reign as king of the
                memes.
              </p>
              <p
                data-aos="fade-left"
                data-aos-duration="700"
                className="text-[18px] lg:text-[23px] pt-7 text-white font-[PepeRegularFont]"
              >
                Pepe is here to make memecoins great again. Launched stealth
                with no presale, zero taxes, LP burnt and contract renounced,
                $PEPE is a coin for the people, forever. Fueled by pure memetic
                power, let $PEPE show you the way.
              </p>
            </div>
          </div>
        </section>
        <section className="section-how-to-buy">
          <img
            src={imgLand}
            className="rotate-180 h-[18px] w-full object-cover"
            alt=""
          />
          <div className="container mx-auto px-[50px] py-[60px]">
            <h2 className="text-[27px] lg:text-[56px] pb-[40px] font-[PepeFont] text-white text-center drop-shadow-[0_2px_2px_rgba(0,0,0,1)]">
              how to buy
            </h2>
            <div
              className="item bg-[#264A20] flex flex-col lg:flex-row gap-[60px] items-center px-5 lg:px-[60px] text-[23px] py-[32px] text-white mb-[29px] 
            rounded-tr-3xl rounded-bl-3xl border-white border-2"
            >
              <div className="w-[221px] shrink-0">
                <img className="w-[60%] mx-auto rotate" src={imgH2B1} alt="" />
              </div>
              <div className="lg:text-left text-center">
                <h3 className="text-[18px] lg:text-[23px] font-[PepeFont] pb-4">
                  create a wallet
                </h3>
                <TypeAnimation
                  cursor={false}
                  sequence={[
                    // Same substring at the start will only be typed out once, initially
                    "download metamask or your wallet of choice from the app store or google play store for free. Desktop users, download the google chrome extension by going to metamask.io.",
                  ]}
                  wrapper="span"
                  speed={50}
                  className="font-[PepeRegularFont] text-[15px] lg:text-[19px]"
                />
              </div>
            </div>
            <div
              className="item bg-[#264A20] flex flex-col lg:flex-row gap-[60px] items-center px-5 lg:px-[60px] text-[23px] py-[32px] text-white mb-[29px] 
            rounded-tr-3xl rounded-bl-3xl border-white border-2"
            >
              <div className="w-[221px] shrink-0">
                <img className="w-[60%] mx-auto rotate" src={imgH2B2} alt="" />
              </div>
              <div className="lg:text-left text-center">
                <h3 className="text-[18px] lg:text-[23px] font-[PepeFont] pb-4">
                  Get Some ETH
                </h3>
                <TypeAnimation
                  cursor={false}
                  sequence={[
                    "have ETH in your wallet to switch to $PEPE. If you don’t have any ETH, you can buy directly on metamask, transfer from another wallet, or buy on another exchange and send it to your wallet.",
                  ]}
                  wrapper="span"
                  speed={50}
                  className="font-[PepeRegularFont] text-[15px] lg:text-[19px]"
                />
              </div>
            </div>
            <div
              className="item bg-[#264A20] flex flex-col lg:flex-row gap-[60px] items-center px-5 lg:px-[60px] py-[32px] text-[23px] text-white mb-[29px] 
            rounded-tr-3xl rounded-bl-3xl border-white border-2"
            >
              <div className="w-[221px] shrink-0">
                <img className="w-[60%] mx-auto rotate" src={imgH2B3} alt="" />
              </div>
              <div className="lg:text-left text-center">
                <h3 className="text-[18px] lg:text-[23px] font-[PepeFont] pb-4">
                  Go to Uniswap
                </h3>
                <TypeAnimation
                  cursor={false}
                  sequence={[
                    "connect to Uniswap. Go to app.uniswap.org in google chrome or on the browser inside your Metamask app. Connect your wallet. Paste the $PEPE token address into Uniswap, select Pepe, and confirm. When Metamask prompts you for a wallet signature, sign.",
                  ]}
                  wrapper="span"
                  speed={50}
                  className="font-[PepeRegularFont] text-[15px] lg:text-[19px]"
                />
              </div>
            </div>
            <div
              className="item bg-[#264A20] flex flex-col lg:flex-row gap-[60px] items-center px-5 lg:px-[60px] text-[23px] py-[32px] text-white mb-[29px] 
            rounded-tr-3xl rounded-bl-3xl border-white border-2"
            >
              <div className="w-[221px] shrink-0">
                <img src={imgH2B4} className="mx-auto rotate" alt="" />
              </div>
              <div className="lg:text-left text-center">
                <h3 className="text-[18px] lg:text-[23px] font-[PepeFont] pb-4">
                  Switch ETH for $PEPE
                </h3>
                <TypeAnimation
                  cursor={false}
                  sequence={[
                    "switch ETH for $PEPE. We have ZERO taxes so you don’t need to worry about buying with a specific slippage, although you may need to use slippage during times of market volatility.",
                  ]}
                  wrapper="span"
                  speed={50}
                  className="font-[PepeRegularFont] text-[15px] lg:text-[19px]"
                />
              </div>
            </div>
          </div>
        </section>
        {/* <section className="section-tokenomics">
          <img
            src={imgLand}
            className="rotate-180 h-[18px] w-full object-cover"
            alt=""
          />
          <div className="container mx-auto px-[50px] py-[60px]">
            <h2 className="text-[27px] lg:text-[56px] pb-[40px] font-[PepeFont] text-white text-center drop-shadow-[0_2px_2px_rgba(0,0,0,1)]">
              TOKENOMICS
            </h2>
            <div className="content flex flex-col lg:flex-row gap-[60px] justify-between lg:px-[90px] text-white">
              <div>
                <p
                  data-aos="zoom-in"
                  className="font-[PepeFont] leading-8 text-center"
                >
                  Token Supply:
                  <br />
                  420,690,000,000,000
                </p>
                <div
                  className="item bg-[#264A20] mt-[38px] flex px-5 lg:px-[60px] text-[23px] py-[32px] text-white mb-[29px] 
            rounded-tr-3xl rounded-bl-3xl border-white border-2"
                >
                  <TypeAnimation
                    cursor={false}
                    sequence={[
                      "No Taxes, No Bullshit.\n\n It’s that simple. 93.1% of the tokens were sent to the liquidity pool, LP tokens were burnt, and contract is renounced. The remaining 6.9% of the supply is being held in a multi-sig wallet only to be used as tokens for future centralized exchange listings, bridges, and liquidity pools. This wallet is easily trackable with the ENS name “pepecexwallet.eth”",
                    ]}
                    speed={50}
                    className="font-[PepeRegularFont] text-[17px] lg:text-[19px] whitespace-pre-line"
                  />
                </div>
              </div>
              <div data-aos="fade-left" className="flex flex-col items-center">
                <p className="font-[PepeRegularFont] text-center">
                  CEX Listings
                  <br />
                  6.9%
                </p>
                <div className="w-[250px] h-[250px] lg:w-[433px] lg:h-[433px] my-6 object-cover shrink-0">
                  <img src={imgPepeMeme} alt="" />
                </div>
                <p className="font-[PepeRegularFont] text-center">
                  Liquidity Pool
                  <br />
                  93.1%
                </p>
              </div>
            </div>
          </div>
        </section> */}
        <section className="section-roadmap">
          <img
            src={imgLand}
            className="rotate-180 h-[18px] w-full object-cover"
            alt=""
          />
          <div className="container mx-auto px-[50px] py-[60px]">
            <h2 className="text-[27px] lg:text-[56px] font-[PepeFont] text-white text-center drop-shadow-[0_2px_2px_rgba(0,0,0,1)]">
              ROADMAP
            </h2>
            <div className="flex flex-col lg:flex-row justify-between items-center">
              <div
                data-aos="fade-right"
                className="w-[293px] h-[297px] my-6 object-cover shrink-0"
              >
                <img src={imgPepePee} alt="" className="" />
              </div>
              <div
                data-aos="flip-left"
                className="item bg-[#264A20] mt-[38px] flex px-1 text-[23px] py-[32px] text-white mb-[29px] 
            rounded-tr-3xl rounded-bl-3xl border-white border-2 h-fit"
              >
                <p className="font-[PepeRegularFont] text-[21px] text-center">
                  Phase 1: Meme <br />
                  Phase 2: Vibe and HODL <br />
                  Phase 3: Meme Takeover
                </p>
              </div>
              <div
                data-aos="fade-left"
                className="w-[293px] h-[297px] my-6 object-cover shrink-0"
              >
                <img src={imgPepeThink} alt="" className="rotate" />
              </div>
            </div>
            <p className="font-[PepeRegularFont] text-[22px] leading-7 text-white text-center">
              All jokes aside, here is a rough sketch of $pepe path ahead. We
              dont wan’t to give everything away on day 1, Expect surprises
              along the way ;)
            </p>
            <div className="flex flex-col lg:flex-row justify-between gap-12">
              <div
                className="item lg:w-1/3 bg-[#264A20] mt-[38px] flex px-6 lg:px-[40px] text-lg py-[32px] text-white mb-[29px] 
            rounded-tr-3xl rounded-bl-3xl border-white border-2 h-fit"
              >
                <div className="font-[PepeRegularFont] text-[19px] ">
                  <h2 className="font-[PepeFont] text-[23px] text-center pb-6">
                    Phase 1
                  </h2>
                  <ul className="leading-9 break-words text-[16px] lg:text-[20px]">
                    <li className="before:content-['\2022'] before:text-black before:mr-[4px] before:-ml-3">
                      Launch
                    </li>
                    <li className="before:content-['\2022'] before:text-black before:mr-[4px] before:-ml-3">
                      CoinGecko/Coinmarketcap Listings
                    </li>
                    <li className="before:content-['\2022'] before:text-black before:mr-[4px] before:-ml-3">
                      1,000+ Holders
                    </li>
                    <li className="before:content-['\2022'] before:text-black before:mr-[4px] before:-ml-3">
                      Get $PEPE Trending on twitter with our memetic power
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className="item lg:w-1/3 bg-[#264A20] mt-[38px] flex px-6 lg:px-[40px] text-lg py-[32px] text-white mb-[29px] 
            rounded-tr-3xl rounded-bl-3xl border-white border-2 h-fit"
              >
                <div className="font-[PepeRegularFont] text-[19px] ">
                  <h2 className="font-[PepeFont] text-[23px] text-center pb-6">
                    Phase 2
                  </h2>
                  <ul className="leading-9 break-words text-[20px]">
                    <li className="before:content-['\2022'] before:text-black before:mr-[4px] before:-ml-3">
                      Community Partnerships Pepe Times digital newsletter
                    </li>
                    <li className="before:content-['\2022'] before:text-black before:mr-[4px] before:-ml-3">
                      Formation of token gated discord group, Pepe Palace, for
                      holders, more details tba
                    </li>
                    <li className="before:content-['\2022'] before:text-black before:mr-[4px] before:-ml-3">
                      CEX Listings 10,000+holders
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className="item lg:w-1/3 bg-[#264A20] mt-[38px] flex px-6 lg:px-[40px] text-lg py-[32px] text-white mb-[29px] 
            rounded-tr-3xl rounded-bl-3xl border-white border-2 h-fit"
              >
                <div className="font-[PepeRegularFont] text-[19px]">
                  <h2 className="font-[PepeFont] text-[23px] text-center pb-6">
                    Phase 3
                  </h2>
                  <ul className="leading-9 break-words text-[20px]">
                    <li className="before:content-['\2022'] before:text-black before:mr-[4px] before:-ml-3">
                      Pepe merch
                    </li>
                    <li className="before:content-['\2022'] before:text-black before:mr-[4px] before:-ml-3">
                      Pepe Academy
                    </li>
                    <li className="before:content-['\2022'] before:text-black before:mr-[4px] before:-ml-3">
                      Pepe Tools
                    </li>
                    <li className="before:content-['\2022'] before:text-black before:mr-[4px] before:-ml-3">
                      T1 Exchange Listings 100,000+ holders
                    </li>
                    <li className="before:content-['\2022'] before:text-black before:mr-[4px] before:-ml-3">
                      Meme Takeover
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <footer className="bg-[#3A963D]">
        <div className="container mx-auto">
          <p className="py-[17px] text-center text-[13px]">
            © 2023 by PEPE. All rights reserved!
          </p>
        </div>
      </footer>
      {isShow && (
        <button
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          className="lg:hidden w-[44px] h-[44px] flex items-center justify-center
           bg-white border border-black rounded-[50%] fixed bottom-11 right-11"
        >
          <img className="w-[28px]" src={iconUp} alt="" />
        </button>
      )}
      {isActive && (
        <div className="menu-sp animate-fadeInDown w-full h-screen bg-[#3A963D] fixed left-0 right-0 top-0 bottom-0 overflow-hidden">
          <div className="flex justify-end">
            <button className="my-6 mr-6" onClick={() => setIsActive(false)}>
              <img className="w-[25px] h-[25px]" src={iconClose} alt="" />
            </button>
          </div>
          <nav>
            <ul className="text-[16px] font-[PepeFont] text-center">
              <li className="p-[10px] mb-6 text-white">
                <a href="/">home</a>
              </li>
              <li className="p-[10px] mb-6 text-white">
                <a href="/">about</a>
              </li>
              <li className="p-[10px] mb-6 text-white">
                <a href="/">how to buy</a>
              </li>
              {/* <li className="p-[10px] mb-6 text-white">
                <a href="/">tokenomics</a>
              </li> */}
              <li className="p-[10px] mb-6 text-white">
                <a href="/">roadmap</a>
              </li>
            </ul>
          </nav>
        </div>
      )}
    </div>
  );
}

export default App;
